import(/* webpackMode: "eager" */ "/app/node_modules/@olinfo/react-components/dist/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["LayoutClient"] */ "/app/src/app/(training)/layout-client.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/icon0.svg");
;
import(/* webpackMode: "eager" */ "/app/src/components/navbar/dropdown-action.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoginButton"] */ "/app/src/components/navbar/login-button.tsx");
